<template>
  <div>
    <h1 class="text-h4">Страница шаблон</h1>
    <v-divider class="my-2"></v-divider>
    <v-skeleton-loader
      v-show="loading"
      class="mx-auto"
      type="table"
    ></v-skeleton-loader>
    <v-card v-show="!loading">
      <v-card-title>
        Почему он используется?
      </v-card-title>
      <v-card-text>
        Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться. Lorem Ipsum
        используют потому, что тот обеспечивает более или менее стандартное заполнение шаблона, а также реальное
        распределение букв и пробелов в абзацах, которое не получается при простой дубликации "Здесь ваш текст.. Здесь
        ваш текст.. Здесь ваш текст.." Многие программы электронной вёрстки и редакторы HTML используют Lorem Ipsum в
        качестве текста по умолчанию, так что поиск по ключевым словам "lorem ipsum" сразу показывает, как много
        веб-страниц всё ещё дожидаются своего настоящего рождения. За прошедшие годы текст Lorem Ipsum получил много
        версий. Некоторые версии появились по ошибке, некоторые - намеренно (например, юмористические варианты).
      </v-card-text>
    </v-card>
    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'

export default {
  ...dashboardPageCommonAttributes,
  name: 'TemplatePage',
  data: function () {
    return {
      loading: true,
      busy: false
    }
  },
  created() {
    setTimeout(() => {
      this.loading = false
    }, 1000)
  },
  head: {
    title: function () {
      return {
        inner: 'Страница шаблон'
      }
    }
  }
}
</script>

<style scoped>

</style>
